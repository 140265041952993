import * as emailValidator from '@rategravity/core-lib/validate-email';

export const validateEmail = (email: string | number | undefined) =>
  typeof email === 'string' && emailValidator.validate(email);

export const validateForm = (fields: Record<string, string | string[] | number | undefined>) =>
  Object.entries(fields).reduce((filtered, [key, value]) => {
    if (typeof value !== 'object' && key === 'email' && !validateEmail(value)) {
      filtered.push(key);
    } else {
      if (typeof value === 'number' && value < 1) {
        filtered.push(key);
      }
      if (typeof value === 'object' && value.length < 1) {
        filtered.push(key);
      }
      if (value === undefined || value === '') {
        filtered.push(key);
      }
    }
    return filtered;
  }, [] as string[]);
